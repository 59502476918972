import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import { setRoleProduct, listRoleProduct } from '@api/rm/role';
export default {
  name: 'dialog-role-product-permission',
  props: {
    /** 产品树 */
    productTree: {
      type: Array,
      default: function _default() {
        return [];
      }
    },

    /** 产品列表 */
    productList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    propsModel: {
      type: Object,
      default: function _default() {}
    },
    defaultCheckedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dialogLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,

      /** 是否选择全部产品 1-全部产品，2-部分产品 */
      isSelectAllProduct: 1,
      treeProps: {
        label: 'label',
        value: 'value',
        children: 'children' // disabled: this.disFn,

      }
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      var _this = this;

      listRoleProduct({
        roleId: this.propsModel.id
      }).then(function (res) {
        // type: 1-全部 2-自定义
        _this.isSelectAllProduct = res.data[0].type === '1' ? 1 : 2;

        if (_this.isSelectAllProduct === 2) {
          _this.$refs.productTree.setCheckedKeys(res.data.map(function (item) {
            return item.productId;
          }) || []);
        }
      });
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
      this.$emit('refresh');
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      var checkedLeafNodes = this.$refs.productTree.getCheckedNodes(true) || [];
      var params = {
        roleId: this.propsModel.id,
        type: this.isSelectAllProduct,
        arrayProductId: this.isSelectAllProduct === 1 ? undefined : checkedLeafNodes.map(function (i) {
          return i.productId;
        })
      };
      this.$emit('update:dialogLoading', true);
      setRoleProduct(params).then(function () {
        _this2.$message.success('产品权限配置成功！');

        setTimeout(function () {
          _this2.dialogClose();
        }, 500);
      }).catch(function () {}).finally(function () {
        _this2.$emit('update:dialogLoading', false);
      });
    }
  }
};