import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogAdd from './components/dialog-add';
import DialogPermission from './components/dialog-permission';
import DialogRoleMenu from './components/dialog-role-menu';
import DialogRoleUser from './components/dialog-role-user';
import DialogSensitive from './components/dialog-sensitive';
import DialogProductPermission from './components/dialog-product-permission';
import { list, remove, roleMenu, getRoleData, listRoleUser, setEnabled } from '@api/rm/role';
import { menuTree } from '@api/rm/roleMenu';
import { getEnumList } from '@api/common/common';
import ENUM from '@/maps/enum/enum';
import VALID from '@/maps/enum/status/valid';
import { list as productList } from '@api/crm/setup/product';
export default {
  name: 'role',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAdd: DialogAdd,
    DialogPermission: DialogPermission,
    DialogRoleMenu: DialogRoleMenu,
    DialogRoleUser: DialogRoleUser,
    DialogSensitive: DialogSensitive,
    DialogProductPermission: DialogProductPermission
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      pagination: {},
      tableData: [],
      query: {},
      showAddDialog: false,
      // 展示新增/编辑弹窗
      currentRow: {},
      // 当前点击行
      enumList: [],
      // 数据权限配置项
      showRoleDataDialog: false,
      // 数据权限配置弹窗
      roleMenuList: [],
      // 角色菜单
      selectedRoleMenu: [],
      // 选中的角色菜单
      roleUserList: [],
      // 角色用户
      showRoleMenuDialog: false,
      // 菜单权限配置弹窗
      showRoleUserDialog: false,
      // 用户权限配置弹窗
      showRoleProductPermissionDialog: false,
      // 产品权限配置弹窗
      permissionDialogLoading: false,

      /** 角色产品树 */
      roleProductTree: [],

      /** 产品列表 */
      productList: [],
      roleId: '',
      selectedDeptList: [],
      // 选中的自定义权限的部门
      showSensitiveDialog: false // 敏感词弹窗

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    // 初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    // 点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    // 点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.loading = true;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      list(param).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.pagination = {
            total: totalCount
          };
          _this.tableData = data;

          _this.tableData.forEach(function (n) {
            // 增加设置状态字段
            if (n.enabled == VALID.NEW.value || n.enabled == VALID.NO.value) {
              n.enabledButton = '启用';
            } else if (n.enabled == VALID.YES.value) {
              n.enabledButton = '停用';
            }
          });
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // TODO 点击新增
    clickEdit: function clickEdit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showAddDialog = true;
    },
    // TODO 点击删除
    clickRemove: function clickRemove(row) {
      var _this2 = this;

      this.$confirm('确定删除该角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var id = _this2.currentRow.id;
        _this2.loading = true;
        remove({
          id: id
        }).then(function (res) {
          _this2.loading = false;

          _this2.init();
        }).catch(function (e) {
          _this2.loading = false;
        });
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    // 设置角色启用/停用
    clickSetEnabled: function clickSetEnabled(row) {
      var _this3 = this;

      var newEnabled = null; // 新状态

      var newEnabledMsg = null; // 新状态文本

      if (row.enabled == VALID.NEW.value || row.enabled == VALID.NO.value) {
        newEnabled = VALID.YES.value;
        newEnabledMsg = '确定启用该角色吗?';
      } else if (row.enabled == VALID.YES.value) {
        newEnabled = VALID.NO.value;
        newEnabledMsg = '确定停用该角色吗?';
      }

      this.$confirm(newEnabledMsg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.loading = true;
        var param = {
          id: row.id,
          enabled: newEnabled
        };
        setEnabled(param).then(function (res) {
          _this3.loading = false;

          _this3.init();
        }).catch(function (e) {
          _this3.loading = false;
        });
      }).catch(function (e) {
        _this3.loading = false;
      });
    },
    // 分配菜单
    clickRoleMenu: function clickRoleMenu(row) {
      var _this4 = this;

      this.roleMenuList = [];
      this.selectedRoleMenu = [];
      this.currentRow = row;
      var param = {
        query: {
          enabled: '1'
        }
      };
      menuTree(param).then(function (data) {
        _this4.roleMenuList = data.data || [];

        _this4.setMenuLabel(_this4.roleMenuList); // 递归设置菜单标签，以区分菜单和按钮


        _this4.roleId = row.id;
        roleMenu({
          roleId: row.id
        }).then(function (res) {
          _this4.selectedRoleMenu = (res.data || []).map(function (item) {
            return item.id;
          });
          _this4.showRoleMenuDialog = true;
        }).catch(function (e) {});
      }).catch(function (e) {});
    },
    // 角色产品权限配置
    clickRoleProduct: function clickRoleProduct(row) {
      var _this5 = this;

      this.currentRow = row;
      this.showRoleProductPermissionDialog = true;
      this.permissionDialogLoading = true;
      productList({
        page: {
          pageNumber: 1,
          pageSize: 99999
        },
        query: {
          right: '0'
        }
      }).then(function (res) {
        var list = res.data;
        var productTree = []; // 将产品列表数据按照目录整合成树形结构

        list.forEach(function (item) {
          // 查找产品树中,当前遍历产品所属的产品类型对象
          var productTypeObj = productTree.find(function (p) {
            return p.value === item.productType;
          }); // 若当前产品的产品类型不在树结构中，则将改产品按层级插入树结构

          if (!productTypeObj) {
            productTree.push({
              label: item.productTypeText,
              value: item.productType,
              productType: item.productType,
              productTypeText: item.productTypeText,
              children: [{
                label: item.category1Text,
                value: item.category1,
                category1: item.category1,
                category1Text: item.category1Text,
                children: [{
                  label: item.category2Text,
                  value: item.category2,
                  category2: item.category2,
                  category2Text: item.category2Text,
                  children: [_objectSpread(_objectSpread({}, item), {}, {
                    label: item.productName,
                    value: item.productId
                  })],
                  productId: "".concat(item.productType, "-").concat(item.category1)
                }],
                productId: "".concat(item.productType, "-").concat(item.category1)
              }],
              productId: "".concat(item.productType)
            });
          } else {
            // 查找产品树中,当前遍历产品所属的产品类型下，是否有当前遍历产品所属的产品目录1
            var productCategory1Obj = productTypeObj.children.find(function (i) {
              return i.value === item.category1;
            });

            if (!productCategory1Obj) {
              productTypeObj.children.push({
                label: item.category1Text,
                value: item.category1,
                category1: item.category1,
                category1Text: item.category1Text,
                children: [{
                  label: item.category2Text,
                  value: item.category2,
                  category2: item.category2,
                  category2Text: item.category2Text,
                  children: [_objectSpread(_objectSpread({}, item), {}, {
                    label: item.productName,
                    value: item.productId
                  })],
                  productId: "".concat(item.productType, "-").concat(item.category1)
                }],
                productId: "".concat(item.productType, "-").concat(item.category1)
              });
            } else {
              // 查找产品树中,当前遍历产品所属的【产品类型-产品目录1】下，是否有当前遍历产品所属的产品目录2
              var productCategory2Obj = productCategory1Obj.children.find(function (i) {
                return i.value === item.category2;
              });

              if (!productCategory2Obj) {
                productCategory1Obj.children.push({
                  label: item.category2Text,
                  value: item.category2,
                  category2: item.category2,
                  category2Text: item.category2Text,
                  children: [_objectSpread(_objectSpread({}, item), {}, {
                    label: item.productName,
                    value: item.productId
                  })],
                  productId: "".concat(item.productType, "-").concat(item.category1, "-").concat(item.category2)
                });
              } else {
                productCategory2Obj.children.push(_objectSpread(_objectSpread({}, item), {}, {
                  label: item.productName,
                  value: item.productId
                }));
              }
            }
          }
        });
        _this5.roleProductTree = productTree;
        _this5.productList = list;
      }).finally(function () {
        _this5.permissionDialogLoading = false;
      });
    },
    // 分配数据
    clickRoleData: function clickRoleData(row) {
      this.roleId = row.id;
      this.currentRow = row;
      this.getEnum(row);
      this.showRoleDataDialog = true;
    },
    // 角色用户
    clickRoleUser: function clickRoleUser(row) {
      var _this6 = this;

      this.currentRow = row;
      var param = {
        roleId: this.currentRow.id
      };
      listRoleUser(param).then(function (res) {
        _this6.roleUserList = res.data || [];
        _this6.showRoleUserDialog = true;
      });
    },
    // 获取数据权限配置项
    getEnum: function getEnum(row) {
      var _this7 = this;

      getEnumList({
        enumCd: ENUM.ENUM_CD_ROLE_DATA
      }).then(function (res) {
        _this7.enumList = res || [];

        if (row.dataType === '4') {
          getRoleData({
            roleId: _this7.roleId
          }).then(function (res) {
            _this7.selectedDeptList = res.data || [];
          });
        }
      });
    },
    refresh: function refresh() {
      this.currentRow = {};
      this.$refs.jTable.onRefresh();
    },
    clickSensitive: function clickSensitive(row) {
      this.currentRow = row;
      this.showSensitiveDialog = true;
    },
    // 递归设置菜单标签(区分按钮和菜单)
    setMenuLabel: function setMenuLabel(menuList) {
      for (var i = 0; i < menuList.length; i++) {
        var n = menuList[i];

        if (n.type == '2') {
          // 按钮
          n.menuName = n.menuName + '(按钮)';
        }

        if (n.children != null && n.children.length > 0) {
          this.setMenuLabel(n.children);
        }
      }
    }
  },
  created: function created() {}
};