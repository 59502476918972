var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        top: "10vh",
        width: "500px",
        "custom-class": "dialog-class",
        title: "【" + _vm.propsModel.name + "】产品权限配置",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "radio-box" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.isSelectAllProduct,
                    callback: function($$v) {
                      _vm.isSelectAllProduct = $$v
                    },
                    expression: "isSelectAllProduct"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("默认产品权限")
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("指定产品权限")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("el-tree", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSelectAllProduct === 2,
                expression: "isSelectAllProduct === 2"
              }
            ],
            ref: "productTree",
            attrs: {
              "default-checked-keys": _vm.defaultCheckedKeys,
              data: _vm.productTree,
              props: _vm.treeProps,
              "node-key": "productId",
              "show-checkbox": ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini" },
              on: { click: _vm.dialogClose }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.dialogLoading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }