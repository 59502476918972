//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import AUDIT from '@/maps/enum/status/audit';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import Attachment from '@/components/business/attachment';
import ContractLog from '@/components/business/contract-log';
import ContractGoldWeight from '@/components/business/contract-gold-weight';
import DialogHistory from '../../dialog-history';
import { setExtendAttribute } from '@/libs/crm/crm';
import { formatAmountToNumber } from '@/libs/crm/format';
import { getInfo as _getInfo, auditMoney } from '@api/crm/interest/interest';
export default {
  name: 'interest-money-audit',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog,
    DialogHistory: DialogHistory,
    ContractGoldWeight: ContractGoldWeight
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: 'tabContract',
      //标签框默认选中
      collapseActive: ['1', '2'],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeReceptionBill: ATTACHMENT_TYPE.RECEPTION_BILL,
      //附件类型：回单凭证
      attachmentTypeInterestApply: ATTACHMENT_TYPE.INTEREST_STAFF,
      //附件类型：付息申请
      attachmentTypeInterestBill: ATTACHMENT_TYPE.INTEREST_BILL,
      //附件类型：付息回单凭证
      showLogDialog: false,
      showHistoryDialog: false,
      contractLogType: LOG_TYPE.INTEREST,
      attachmentOpened: '',
      //附件打开状态
      selectData: {
        customerVisitClaim: [] //客户是否接受回访

      },
      formModel: {},
      //表单
      rules: {}
    };
  },
  created: function created() {},
  computed: {
    // 续约收益金额判断
    isLast: function isLast() {
      var f = false;

      if ((this.model.rentRansomTypeText === '回收黄金珠宝实物' || this.model.rentRansomTypeText === '归还黄金珠宝实物') && this.model.isLast === 1) {
        f = true;
      }

      return f;
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; //默认打开第一个标签页
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            arrayIpdId: [_this.model.ipdId],
            audit: result,
            advice: _this.formModel.advice
          };
          _this.loading = true;
          auditMoney(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit('refresh');
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var ipdId = this.propsModel.ipdId; //this.loading = true;

      _getInfo({
        ipdId: ipdId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.model = data;
        setExtendAttribute(_this2.model); //包装必要展示字段
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    },
    //显示付息历史
    showHistory: function showHistory() {
      this.showHistoryDialog = true;
    },
    //变更付息金额
    changeInterest: function changeInterest() {
      var _this3 = this;

      this.$confirm('系统付息金额为' + this.model.interest + '，是否变更?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {}).catch(function () {
        _this3.$set(_this3.formModel, 'interest', formatAmountToNumber(_this3.model.interest));
      });
    }
  }
};