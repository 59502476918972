var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c(
        "el-row",
        { staticClass: "page-table-container", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            {
              staticClass: "list-container",
              staticStyle: { width: "1360px" },
              attrs: { span: 24 }
            },
            [
              _c("dynamic-form", {
                ref: "form",
                attrs: { props: _vm.filterProps, forms: _vm.filterForms }
              }),
              _c(
                "el-row",
                {
                  staticClass: "actions",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-plus"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickEdit({})
                            }
                          }
                        },
                        [_vm._v("新增")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh" },
                          on: {
                            click: function($event) {
                              return _vm.clickRefresh()
                            }
                          }
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            size: "mini",
                            icon: "el-icon-bottom-left"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickReset()
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickSearch()
                            }
                          }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "dynamic-table",
                {
                  ref: "jTable",
                  staticStyle: { width: "1360px" },
                  attrs: {
                    headers: _vm.headers,
                    table: _vm.table,
                    pagination: _vm.pagination,
                    data: _vm.tableData,
                    loading: _vm.loading
                  },
                  on: { load: _vm.loadData, "current-change": _vm.selectRow }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      slot: "operation",
                      fixed: "right",
                      label: "操作",
                      width: "450px"
                    },
                    slot: "operation",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "role-menuConfig",
                                    expression: "'role-menuConfig'"
                                  }
                                ],
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickRoleMenu(row)
                                  }
                                }
                              },
                              [_vm._v("菜单权限配置")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "role-dataConfig",
                                    expression: "'role-dataConfig'"
                                  }
                                ],
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickRoleData(row)
                                  }
                                }
                              },
                              [_vm._v("数据权限配置")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "role-menuConfig",
                                    expression: "'role-menuConfig'"
                                  }
                                ],
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickRoleProduct(row)
                                  }
                                }
                              },
                              [_vm._v("产品权限配置")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "role-maskConfig",
                                    expression: "'role-maskConfig'"
                                  }
                                ],
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickSensitive(row)
                                  }
                                }
                              },
                              [_vm._v("敏感字段配置")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "role-userConfig",
                                    expression: "'role-userConfig'"
                                  }
                                ],
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickRoleUser(row)
                                  }
                                }
                              },
                              [_vm._v("角色用户")]
                            ),
                            row.code != "0000" && row.code != "0001"
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "role-edit",
                                        expression: "'role-edit'"
                                      }
                                    ],
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickEdit(row)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            row.code != "0000" && row.code != "0001"
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "role-delete",
                                        expression: "'role-delete'"
                                      }
                                    ],
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickRemove(row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                            row.code != "0000" && row.code != "0001"
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "role-status",
                                        expression: "'role-status'"
                                      }
                                    ],
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickSetEnabled(row)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(row.enabledButton))]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dialog-add", {
        attrs: { propsModel: _vm.currentRow },
        on: { refresh: _vm.init },
        model: {
          value: _vm.showAddDialog,
          callback: function($$v) {
            _vm.showAddDialog = $$v
          },
          expression: "showAddDialog"
        }
      }),
      _c("dialog-permission", {
        attrs: {
          enumList: _vm.enumList,
          selectedDeptList: _vm.selectedDeptList,
          propsModel: _vm.currentRow
        },
        on: { refresh: _vm.refresh },
        model: {
          value: _vm.showRoleDataDialog,
          callback: function($$v) {
            _vm.showRoleDataDialog = $$v
          },
          expression: "showRoleDataDialog"
        }
      }),
      _c("dialog-role-menu", {
        attrs: {
          menuList: _vm.roleMenuList,
          defaultCheckedKeys: _vm.selectedRoleMenu,
          propsModel: _vm.currentRow
        },
        on: { refresh: _vm.refresh },
        model: {
          value: _vm.showRoleMenuDialog,
          callback: function($$v) {
            _vm.showRoleMenuDialog = $$v
          },
          expression: "showRoleMenuDialog"
        }
      }),
      _c("dialog-sensitive", {
        attrs: { propsModel: _vm.currentRow },
        on: { refresh: _vm.refresh },
        model: {
          value: _vm.showSensitiveDialog,
          callback: function($$v) {
            _vm.showSensitiveDialog = $$v
          },
          expression: "showSensitiveDialog"
        }
      }),
      _c("dialog-role-user", {
        attrs: { propsModel: _vm.currentRow, userList: _vm.roleUserList },
        on: { refresh: _vm.init },
        model: {
          value: _vm.showRoleUserDialog,
          callback: function($$v) {
            _vm.showRoleUserDialog = $$v
          },
          expression: "showRoleUserDialog"
        }
      }),
      _c("dialog-product-permission", {
        attrs: {
          dialogLoading: _vm.permissionDialogLoading,
          productTree: _vm.roleProductTree,
          productList: _vm.productList,
          propsModel: _vm.currentRow
        },
        on: {
          "update:dialogLoading": function($event) {
            _vm.permissionDialogLoading = $event
          },
          "update:dialog-loading": function($event) {
            _vm.permissionDialogLoading = $event
          },
          refresh: _vm.refresh
        },
        model: {
          value: _vm.showRoleProductPermissionDialog,
          callback: function($$v) {
            _vm.showRoleProductPermissionDialog = $$v
          },
          expression: "showRoleProductPermissionDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }